import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Context } from 'store/provider';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Layout from 'components/layout';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { DevelopmentIcon } from 'components/ui';
import { Typography } from '@material-ui/core';

import HolderImage from 'images/rectory_forthcoming_holder.jpg';

const styles = (theme) => ({
  wrapper: {
    maxWidth: '80vw',
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.up('md')]: {
      maxWidth: '250px',
      left: 'auto',
      transform: 'translateX(0px)',
    },
  },
});

function PropertySearch(props) {
  const { classes } = props;
  const [state, dispatch] = useContext(Context);
  const [items, setItems] = useState([]);

  useEffect(() => {
    state.searchResults.sort(function (a, b) {
      return a.node.data.distance - b.node.data.distance;
    });

    if (state.searchResults.length > 0) {
      setItems(state.searchResults);
    } else {
      navigate('/');
    }
  }, []);

  return (
    <Layout>
      <Container maxWidth={process.env.CONTAINER_WIDTH}>
        <div className="spaced-grid-wrapper">
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                my={10}
              >
                <Typography component="h2" variant="h2" color="textPrimary">
                  Search Results
                </Typography>
                <Typography
                  component="p"
                  variant="body1"
                  color="textSecondary"
                  align="center"
                >
                  Rectory excels at creating stunning developments in highly
                  desirable locations, with beautiful properties that are full
                  of character, that our customers are proud to call home.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mb={4}>
                <Typography
                  component="p"
                  variant="body1"
                  align="center"
                  style={{ fontWeight: 'bold' }}
                >
                  We have {state.searchResults.length} development
                  {state.searchResults.length > 1 ? 's' : null} within{' '}
                  {state.searchRadius} miles of {state.searchLocationReadable}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            {items.map((item, key) => {
              return key === 0 ? (
                <Grid item xs={12} key={key}>
                  <FeaturedItem data={item} classes={classes} />
                </Grid>
              ) : (
                <Grid item xs={12} lg={6} key={key}>
                  <StandardItem data={item} classes={classes} />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </Container>
    </Layout>
  );
}

PropertySearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PropertySearch);

const FeaturedItem = (props) => {
  const { data, classes } = props;
  return (
    <Box
      component={Grid}
      container
      alignItems="center"
      justifyContent={{ xs: 'flex-end', md: 'center' }}
      position="relative"
    >
      <Box position="absolute" className={classes.wrapper}>
        <DevelopmentIcon item={data} border={true} expanded={true} />
      </Box>
      {data.node.data.listing_images.map((item, key) => {
        return (
          <Grid style={{ display: 'flex' }} item xs={12} lg={6} key={key}>
            <img
              className="w-100"
              src={`${item.image.url}&ar=2:1.5&fit=crop`}
              alt={item.image.alt ? item.image.alt : ''}
            />
          </Grid>
        );
      })}
    </Box>
  );
};

FeaturedItem.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const StandardItem = (props) => {
  const { data, portfolio, classes } = props;
  console.log(data);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      position="relative"
    >
      <Box position="absolute" className={classes.wrapper}>
        <DevelopmentIcon
          item={data}
          border={true}
          expanded={portfolio ? false : true}
          portfolio={portfolio ? portfolio : false}
        />
      </Box>
      {data.node.data.listing_images.length < 2 && (
        <Box display="flex" pl={[0, 0, 10, 10]}>
          <img className="w-100" src={HolderImage} alt="" />
        </Box>
      )}
      {data.node.data.listing_images.map((item, key) => {
        if (portfolio && key > 0) return null;
        return (
          <Box key={key} display="flex" pl={[0, 0, 10, 10]}>
            <img
              className="w-100"
              src={`${item.image.url}&ar=2:1.4&fit=crop`}
              alt=""
            />
          </Box>
        );
      })}
    </Box>
  );
};

StandardItem.propTypes = {
  data: PropTypes.object.isRequired,
  portfolio: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};
